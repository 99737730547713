<template>
  <v-container fluid>
    <v-toolbar flat></v-toolbar>
    <v-row justify="center">
      <v-col sm="12" md="10" lg="10">
        <v-card outlined tile class="mb-10">
          <terms></terms>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Terms from '../public/TermsCode'
export default {
  components: { Terms }
}
</script>
